<template>
  <div>
    <!-- 搜索条件 -->
    <el-card class="box-card">
      <el-form :inline="true" class="mt10" size="medium">
        <el-form-item>
          <el-input placeholder="姓名/手机号" v-model="form.key" clearable style="width:160px" @keyup.enter.native="search">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.roleId" placeholder="角色" clearable style="width:160px">
            <el-option :value="item.roleId" :label='item.roleName' v-for="item in roleLists" :key="item.roleId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.enabled" placeholder="账号状态" clearable style="width:160px">
            <el-option :value="1" label='启用'>启用</el-option>
            <el-option :value="2" label='禁用'>禁用</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click='getUserLists(1)'>搜索</el-button>
          <el-button type="primary" @click="userDialog = true">新增</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <!-- 数据表格 -->
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="userName" label="姓名" align='center' />
        <el-table-column prop="rolesStr" label="角色" align='center' :formatter="formatRole" />
        <el-table-column prop="enabled" label="账号状态" align='center' :formatter="formatEnabled" />
        <el-table-column prop="createTime" label="创建时间" align='center' :formatter="formatTime" />
        <el-table-column prop="remark" label="备注" align='center' />
        <el-table-column label="操作" fixed="right" align="center" width="260px">
          <template slot-scope="scope">
            <div v-if="roleType(scope.row.rolesSysStr)">
              <el-button size="mini" type="primary" @click="editUserInfo(scope.row)">编辑</el-button>
              <el-button size="mini" :type="scope.row.enabled===1?'primary':'info'" @click="openDialog(1, scope.row)">
                {{scope.row.enabled===1?'禁用':'启用'}}</el-button>
              <el-button size="mini" type="primary" @click="openDialog(2, scope.row)">重置密码</el-button>
              <el-button size="mini" type="primary" @click="openDialog(3, scope.row)">删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background :page-count="totalPages" :current-page.sync='form.pageCurrent'
        @current-change="(val)=>{getUserLists('', val)}" class="userPagination">
      </el-pagination>
    </el-card>

    <!--新增|编辑用户 弹框 -->
    <el-dialog :title="userInfo.userId?'编辑用户':'新增用户'" :visible.sync="userDialog" :show-close="false" :close-on-click-modal="false"
      :close-on-press-escape="false" width="480px" center class="userDialog">
      <el-form ref="form" :rules="rules" :model="userInfo" label-width="80px">
        <el-form-item label="姓名:" prop="userName">
          <el-input placeholder="请输入用户姓名" v-model="userInfo.userName"></el-input>
        </el-form-item>
        <el-form-item label="登录账号:" prop="account">
          <el-input placeholder="请输入用户手机号作为登录账号" type="number" v-model="userInfo.account"></el-input>
        </el-form-item>
        <el-form-item label="初始密码:" prop="password" v-if="!userInfo.userId">
          <el-input placeholder="请输入6-18位密码(包含数字和字母)" v-model="userInfo.password"></el-input>
        </el-form-item>
        <el-form-item label="所属部门:" prop="deptId">
          <el-cascader :options="options" :props="porps" filterable clearable v-model="userInfo.deptId" :disabled="!!userInfo.userId"
            @change="getRolesByDepartment">
          </el-cascader>
        </el-form-item>
        <el-form-item label="角色权限:" prop="rolesStr">
          <el-select placeholder="请选择角色权限" v-model="userInfo.rolesStr">
            <el-option :value="item.roleId" :label='item.roleName' v-for="item in dynamicRole" :key="item.roleId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注:">
          <el-input type="textarea" v-model="userInfo.remark"></el-input>
        </el-form-item>
        <el-form-item class="userDialog-btns">
          <el-button type="primary" :loading="submit" :disabled="submit" @click="commitUserInfo('form')">确认</el-button>
          <el-button @click="resetData">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  $user_userLists, $user_roleLists, $user_editUser, $user_disabledUser, $user_deleteUser,
  $user_department, $user_roleByDepartment, $user_addUser, $user_resetPassword,
} from '@/api/userManagement';
import { formatDate, deepClone } from '@/utils';

export default {
  components: {
  },
  data() {
    const validateAccount = (rule, value, callback) => {
      const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(value)) {
        callback(new Error('请输入正确的手机号'));
      } else {
        callback();
      }
    };
    const validatePassword = (rule, value, callback) => {
      const reg_pwd = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\\\W]{6,18}$/;
      if (!reg_pwd.test(value)) {
        callback(new Error('请输入6-18位密码(包含数字和字母)'));
      } else {
        callback();
      }
    };
    return {
      totalPages: 1, // 总页数
      tableData: [], // 表格数据
      roleLists: [], // 角色下拉
      dynamicRole: [], // 根据部门查询的角色
      options: [], // 所有部门信息
      userDialog: false, // 新增编辑用户弹框
      submit: false,
      porps: {
        value: 'deptId',
        label: 'deptName',
        checkStrictly: true,
      },
      // 查询条件
      form: {
        pageCurrent: 1, // 当前页
        pageSize: 10,
        key: null, // 电话
        roleId: null, // 角色
        enabled: null, // 启用状态 1启用 2禁用
      },
      // 用户信息
      userInfo: {
        userName: '',
        account: '',
        password: '',
        deptId: [],
        rolesStr: null,
        remark: '',
      },

      rules: {
        userName: [
          { required: true, message: '请输入用户姓名', trigger: ['change', 'blur'] },
        ],
        account: [
          { validator: validateAccount, required: true, trigger: ['blur', 'change'] },
        ],
        password: [
          { validator: validatePassword, required: true, trigger: ['blur', 'change'] },
        ],
        deptId: [
          { required: true, message: '请选择所属部门', trigger: 'change' },
        ],
        rolesStr: [
          { required: true, message: '请选择角色权限', trigger: 'change' },
        ],
      },
    };
  },
  created() {
    this.getRoles();
  },
  mounted() {
    this.getUserLists();
    this.getDepartment();
  },
  methods: {
    // 编辑用户信息
    async editUserInfo(row) {
      await this.getRolesByDepartment(row.deptId);
      this.userInfo = deepClone(row);
      this.userInfo.rolesStr = Number(row.rolesStr.split(',')[0]);
      this.userDialog = true;
    },
    // 提交
    commitUserInfo(formName) {
      this.submit = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const info = deepClone(this.userInfo);
          if (this.userInfo.deptId.length) {
            info.deptId = Number(this.userInfo.deptId.slice(-1));
          }
          info.rolesStr = String(this.userInfo.rolesStr);
          if (this.userInfo.userId) { // 编辑
            $user_editUser(info).then(() => {
              this.getUserLists();
              this.resetData();
            }).catch(() => {
              this.submit = false;
            });
          } else { // 新增
            $user_addUser(info).then(() => {
              this.getUserLists();
              this.resetData();
            }).catch(() => {
              this.submit = false;
            });
          }
          return true;
        } else {
          this.submit = false;
          return false;
        }
      });
    },
    // 用户列表分页查询
    getUserLists(type) {
      if (type) {
        this.form.pageCurrent = 1;
      }
      $user_userLists(this.form).then(({ records, pages }) => {
        this.tableData = records;
        this.totalPages = pages;
      });
    },
    // 重置
    resetData() {
      this.submit = false;
      this.userDialog = false;
      this.userInfo = this.$options.data().userInfo;
      this.$refs.form.resetFields();
    },
    // 获取所有角色
    async getRoles() {
      this.roleLists = await $user_roleLists();
    },
    // 打开禁用或者重置密码弹框 type 1禁用 2重置密码 3删除当前用户
    openDialog(type, user) {
      const tishiOb = {
        1: { content: `是否${user.enabled === 1 ? '禁用' : '启用'}当前用户`, method: $user_disabledUser },
        2: { content: '是否重置当前密码', method: $user_resetPassword },
        3: { content: '是否删除当前用户', method: $user_deleteUser },
      };
      this.$confirm(tishiOb[type].content, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        const enabled = user.enabled === 1 ? 2 : 1;
        tishiOb[type].method(user.userId, enabled).then(() => {
          this.getUserLists();
        });
      });
    },
    // 根据部门id 查询角色
    getRolesByDepartment(partmentId) {
      const Id = partmentId.length ? this.userInfo.deptId.slice(-1) : partmentId;
      this.userInfo.rolesStr = '';
      return new Promise((resolve, reject) => {
        $user_roleByDepartment(Id).then((res) => {
          this.dynamicRole = res;
          resolve();
        }).catch((err) => {
          reject(err);
        });
      });
    },
    // 获取部门数据
    getDepartment() {
      $user_department().then((res) => {
        this.options = res;
      });
    },
    // 格式化时间字段
    formatTime({ createTime }) {
      return formatDate(createTime, 'yyyy-MM-dd HH:mm:ss');
    },
    // 格式化账号状态
    formatEnabled({ enabled }) {
      return enabled === 1 ? '启用' : '禁用';
    },
    roleType(val) {
      const roleArr = val.split(',');
      return !(roleArr.indexOf('1') > -1);
    },
    // 格式化 用户角色
    formatRole({ rolesStr }) {
      let userRoles = [];
      let role = '';
      if (rolesStr) {
        userRoles = rolesStr.split(',');
        const arrLength = userRoles.length;
        userRoles.forEach((item, index) => {
          const nowRole = this.roleLists.find((roleItem) => roleItem.roleId === Number(item));
          if (nowRole) {
            if (index < arrLength - 1) {
              role += `${nowRole.roleName},`;
            } else {
              role += nowRole.roleName;
            }
          }
        });
      }
      return role;
    },
  },
};
</script>

<style scoped lang="scss">
.box-card {
  margin-bottom: 6px;
  /deep/.el-card__body {
    padding-bottom: 0;
  }
}
.userPagination.el-pagination {
  margin-top: 20px;
}
/deep/.userDialog {
  .el-dialog__header {
    text-align: left;
    font-weight: bold;
  }
  .el-dialog__title::before {
    content: "";
    display: inline-block;
    transform: translateY(3px);
    width: 4px;
    height: 20px;
    border-radius: 2px;
    margin-right: 6px;
    background: red;
  }
  .el-dialog {
    border-radius: 20px;
  }
  .el-form-item {
    .el-form-item__label {
      text-align: left;
      width: 90px !important;
    }
    .el-form-item__content {
      margin-left: 90px !important;
    }
  }
  .el-select,
  .el-cascader {
    width: 100%;
  }
  .userDialog-btns {
    .el-form-item__content {
      display: flex;
      justify-content: space-around;
      margin-left: 0 !important;
      margin-top: 30px;
      .el-button {
        width: 160px;
      }
    }
  }
}
</style>
