import api from './api';

// 查询部门树
export const $user_department = (params) => api.get('/j/platform/t-spread-sys-dept/trees', params);
// 根据部门id 查询角色 
export const $user_roleByDepartment = (params) => api.get(`/j/platform//t-spread-sys-role/dim/sysRoles/${params}`);
// 用户管理分页查询
export const $user_userLists = (params) => api.get('/j/platform/t-spread-sys-user/page', params);
// 获取所有角色信息
export const $user_roleLists = (params) => api.get('/j/platform/t-spread-sys-role/dim/sysRoles', params);
// 新增用户
export const $user_addUser = (params) => api.post('/j/platform/t-spread-sys-user/add', params);
// 编辑用户 
export const $user_editUser = (params) => api.post('/j/platform/t-spread-sys-user/update', params);
// 禁用用户   
export const $user_disabledUser = (userId, enabled) => api.post(`/j/platform/t-spread-sys-user/disable/${userId}/${enabled}`);
// 重置密码  
export const $user_resetPassword = (userId) => api.post(`/j/platform/t-spread-sys-user/resetPwd/${userId}`);
// 删除用户
export const $user_deleteUser = (userId) => api.post(`/j/platform/t-spread-sys-user/delete/${userId}`);
// 

