import api from './api';

// 分页查询一级部门
export const $sg_spreadSysDeptPage = (params) => api.get('/j/platform/t-spread-sys-dept/page', params);

// 查询下级部门
export const $sg_spreadSysDeptTree = (params) => api.get(`/j/platform/t-spread-sys-dept/tree/${params}`);

// 查询单个
export const $sg_spreadSysDeptGet = (params) => api.get(`/j/platform/t-spread-sys-dept/get/${params}`);

// 新增部门
export const $sp_spreadSysDeptAdd = (params) => api.post('/j/platform/t-spread-sys-dept/add', params);

// 修改部门
export const $sp_spreadSysDeptUpdate = (params) => api.post('/j/platform/t-spread-sys-dept/update', params);

// 删除部门
export const $sp_spreadSysDeptDelete = (params) => api.post(`/j/platform/t-spread-sys-dept/delete/${params}`);
