<template>
    <div class="role">
      <div class="roleBtnGroup">
        <el-button type="primary" @click="addEditRole">新增角色</el-button>
      </div>
       <el-table
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;min-height:580px;">
            <el-table-column
                prop="roleName"
                label="角色名称"
                >
            </el-table-column>
            <el-table-column
                prop="userCnt"
                label="角色人数"
                >
            </el-table-column>
            <el-table-column
                label="更新时间">
                <template slot-scope="scope">
                    <span>{{$formatDate(scope.row.updateTime, 'yyyy-MM-dd HH:mm:ss')}}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="remark"
                label="说明">
            </el-table-column>
            <el-table-column
                label="操作">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.ifSys !== 1" type="text" @click="addEditRole(scope.row)">编辑</el-button>
                    <el-button v-if="scope.row.ifSys !== 1" type="text" @click="delRole(scope.row)">删除</el-button>
                    <span v-if="scope.row.ifSys === 1" style="color:#999;">{{'超级管理员不可操作'}}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page="pageCurrent"
            :page-size="pageSize"
            background
            layout="prev, pager, next, jumper"
            :total="total">
        </el-pagination>
        <!-- 新增 -->
        <el-dialog
         @close='cancel'
         width="700px" :title="this.addOrEdit === 1 ? '新增角色' : '编辑角色'" :visible.sync="dialogFormVisible">
            <el-form :rules="rules" ref="addRoleForm" :model="addRoleForm">
                <el-form-item prop="roleName" label="角色名称:" :label-width="formLabelWidth">
                    <el-input style="width:200px;" v-model="addRoleForm.roleName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item  prop="remark" label="角色说明:" :label-width="formLabelWidth">
                    <el-input type='textarea' v-model="addRoleForm.remark" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item prop='deptId' label="角色部门:" :label-width="formLabelWidth">
                    <el-cascader :disabled='addOrEdit === 2'
                    checkStrictly :options="options" :props="props" v-model="addRoleForm.deptId" ></el-cascader>
                </el-form-item>
                <el-form-item label="角色权限:" :label-width="formLabelWidth">
                    <el-tree
                      :data="data"
                      show-checkbox
                      node-key="rights"
                      ref="tree"
                      default-expand-all
                      :default-checked-keys="clickTree"
                      @check-change="handleCheckChange"
                      :expand-on-click-node="false">
                      <span class="custom-tree-node" slot-scope="{ data }">
                        <span>{{ data.title }}</span>
                        <span>
                           <el-checkbox-group  v-model="data.checkList">
                              <el-checkbox
                              :disabled='data.disabledRight'
                              v-for="item in data.childRights" :key="item.value"
                              :label="item.name"></el-checkbox>
                           </el-checkbox-group>
                        </span>
                      </span>
                    </el-tree>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click='ConfirmRole'>确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  $sg_spreadSysRolePage, $sp_spreadSysRoleAdd, $sp_spreadSysRoleDel, $sp_spreadSysRoleUpdate, $sg_spreadSysDeptTrees,
} from '@/api/rolePermissions';
import { getRouteDataByRights } from '@/router/routeMap';
import { deepClone } from '@/utils/base/utils';

export default {
  data() {
    const checkRoleName = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('角色名不能为空'));
      } else {
        callback();
      }
    };
    const checkRemark = (rule, value, callback) => {
      if (!value) {
        callback(new Error('角色说明不能为空'));
      } else {
        callback();
      }
    };
    const checkDept = (rule, value, callback) => {
      if (!value) {
        callback(new Error('角色部门不能为空'));
      } else {
        callback();
      }
    };
    return {
      dialogFormVisible: false,
      formLabelWidth: '100px',
      // 提交的表单
      addRoleForm: {
        roleName: '',
        remark: '',
        deptId: '',
        roleAuthGroup: [],
        roleId: '',
        ifSys: 2,
      },
      addOrEdit: 1, // 1 新增 2 编辑
      rules: {
        roleName: [
          {
            validator: checkRoleName, trigger: 'blur',
          },
        ],
        remark: [
          {
            validator: checkRemark, trigger: 'blur',
          },
        ],
        deptId: [
          {
            validator: checkDept, trigger: 'change',
          },
        ],
      },
      pageCurrent: 1,
      pageSize: 10,
      total: 0,
      // 级联
      options: [],
      props: {
        value: 'deptId',
        label: 'deptName',
        checkStrictly: true,
      },
      //
      tableData: [], // 表格数据
      data: [], // tree数据
      clickTree: [], // 选中的tree
      buttonList: [], // 选中的按钮
      btnArr: [],
    };
  },
  methods: {
    handleCheckChange(nodeData, Checked) {
      this.clickTree = this.$refs.tree.getCheckedKeys();
      // eslint-disable-next-line no-param-reassign
      nodeData.disabledRight = !Checked;
    },
    async getRoleList() {
      const params = {
        pageCurrent: this.pageCurrent,
        pageSize: this.pageSize,
      };
      const res = await $sg_spreadSysRolePage(params);
      this.tableData = res.records;
      this.total = res.total;
    },
    async getRoleDeptList() {
      const res = await $sg_spreadSysDeptTrees();
      this.options = res;
    },
    addEditRole(row) {
      this.dialogFormVisible = true;
      if (Object.prototype.toString.call(row) === '[object Object]') {
        // 编辑
        this.backfill(this.data, row.roleAuthGroup);
        this.addOrEdit = 2;
        this.addRoleForm = deepClone({
          roleId: row.roleId,
          deptId: row.deptId,
          roleName: row.roleName,
          remark: row.remark,
          roleAuthGroup: row.roleAuthGroup,
          ifSys: 2,
        });
      } else {
        // 新增
        this.addOrEdit = 1;
      }
    },
    // 回填
    backfill(data, group) {
      const list = JSON.parse(group);
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.backfill(item.children, group);
          return;
        }
        // 按钮回填
        if (item.rights) {
          if (item.childRights) {
            item.childRights.forEach((chi) => {
              list.forEach((li) => {
                if (chi.value === li) {
                  item.checkList.push(chi.name);
                }
              });
            });
          }
        }
        // tree回填
        list.forEach((lis) => {
          if (item.rights === lis) {
            this.btnArr.push(lis);
            this.clickTree = this.btnArr;
            item.disabledRight = false;
          }
        });
      });
    },
    // 处理参数
    handleParameter(data) {
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.handleParameter(item.children);
          return;
        }
        // 给每一条数据新增 buttonList
        item.buttonList = [];
        if (this.clickTree.includes(item.rights) && (item.checkList && item.checkList.length > 0)) {
          item.childRights.forEach((child) => {
            item.checkList.forEach((ch) => {
              if (child.name === ch) {
                item.buttonList.push(child.value);
              }
            });
          });
        }
      });
    },
    // 处理按钮参数
    handleButtonList(data) {
      const arr = [];
      // 合并每一条的buttonList
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.children.forEach((childItem) => {
            if (childItem.rights) {
              arr.push(...childItem.buttonList);
            } 
          });
        } else if (item.rights) {
          arr.push(...item.buttonList);
        }
        this.addRoleForm.roleAuthGroup = arr;
      });
    },
    async ConfirmRole() {
      this.handleParameter(this.data);
      this.handleButtonList(this.data);
      // 合并clickTree和buttonList，过滤第一级路由name
      this.clickTree = this.clickTree.filter((item) => item.indexOf('_') !== 0);
      this.addRoleForm.roleAuthGroup = [...this.addRoleForm.roleAuthGroup, ...this.clickTree];
      this.$refs.addRoleForm.validate((valid) => {
        if (!valid) return;
        if (this.clickTree.length === 0) {
          this.$message.error('至少要授权一个菜单');
          return;
        }
        if (this.addOrEdit === 1) {
          // 新增
          const params = {
            deptId: this.addRoleForm.deptId[this.addRoleForm.deptId.length - 1],
            roleName: this.addRoleForm.roleName,
            remark: this.addRoleForm.remark,
            roleAuthGroup: JSON.stringify(this.addRoleForm.roleAuthGroup),
            ifSys: 2,
          };
          $sp_spreadSysRoleAdd(params).then(() => {
            this.dialogFormVisible = false;
            this.getRoleList();
          });
        } else {
          // 编辑
          let id = this.addRoleForm.deptId;
          if (typeof id !== 'number') {
            id = this.addRoleForm.deptId[this.addRoleForm.deptId.length - 1];
          }
          const params = {
            ...this.addRoleForm,
            roleAuthGroup: JSON.stringify(this.addRoleForm.roleAuthGroup),
            deptId: id,
          };
          $sp_spreadSysRoleUpdate(params).then(() => {
            this.dialogFormVisible = false;
            this.getRoleList();
          });
        }
      });
    },
    // 关闭模态框清空表单
    cancelCheckList(data) {
      data.forEach((item) => {
        if (item.children && item.children.length > 0) {
          this.cancelCheckList(item.children);
          return;
        }
        // 清空选中的按钮
        if (item.checkList && item.checkList.length > 0) {
          item.checkList = [];
        }
      });
    },
    cancel() {
      this.dialogFormVisible = false;
      this.$refs.addRoleForm.resetFields();
      this.addRoleForm = {
        roleName: '',
        remark: '',
        deptId: '',
        roleAuthGroup: [],
        roleId: '',
        ifSys: 2,
      };
      this.clickTree = [];
      this.btnArr = [];
      this.$nextTick(() => {
        // 清空选中的tree
        this.$refs.tree.setCheckedKeys(this.clickTree);
      });
      this.cancelCheckList(this.data);
    },
    delRole(row) {
      const self = this;
      this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        $sp_spreadSysRoleDel(row.roleId).then(() => {
          self.getRoleList();
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    handleCurrentChange(val) {
      this.pageCurrent = val;
      this.getRoleList();
    },
    // 修改路由
    filterRoutes(route) {
      route.forEach((item) => {
        if (item.children && item.children.length > 0) {
          item.rights = item.rights || `_${item.name}`;
          this.filterRoutes(item.children);
          return;
        }
        item.rights = item.rights || item.name;
        if (item.childRights) {
          // 新增选中的按钮数组
          item.checkList = [];
          // 分割按钮数组
          item.childRights = item.childRights.map((rightsItem) => ({
            value: rightsItem.split('@')[0],
            name: rightsItem.split('@')[1],
            rights: item.rights,
          }));
          // 新增禁用属性
          if (this.clickTree.includes(item.name)) {
            item.disabledRight = false;
          } else {
            item.disabledRight = true;
          }
        }
      });
    },
  },
  mounted() {
    this.getRoleList();
    this.getRoleDeptList();
    const data = deepClone(getRouteDataByRights());
    this.filterRoutes(data);
    this.data = data;
  },
};
</script>

<style lang="scss" scoped>
.role{
        height: 100%;
        width: 100%;
        background: #fff;
        border-radius: 6px;
        padding: 30px 20px;
        box-sizing: border-box;
}
.roleBtnGroup{
    margin-bottom: 40px;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
/deep/ .el-form{
   height:500px;
   overflow:auto;
   padding-right: 20px;
   box-sizing: border-box;
}
</style>
