<template>
  <div class="departmentManager">
    <div class="departmentBtnGroup">
      <PrevReclick rights="p_t-spread-sys-dept_add">
        <el-button type="primary" @click="addOneLevelMenu">新增一级菜单</el-button>
      </PrevReclick>
    </div>
    <el-table
        ref="table"
        :data="tableData"
        style="width: 100%;margin-bottom: 20px;min-height:580px;"
        row-key="deptId"
        border
        lazy
        :load="load"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column
            prop="deptName"
            label="部门名称">
        </el-table-column>

        <el-table-column
            prop="deptAddress"
            label="部门地址">
        </el-table-column>
        
        <el-table-column
            prop="remark"
            label="部门描述">
        </el-table-column>

        <el-table-column
            label="操作">
            <template slot-scope='scope'>
              <PrevReclick rights="p_t-spread-sys-dept_add">
                <el-button type="text" @click="addChildMenu(scope.row)">新增下级部门</el-button>
              </PrevReclick>
              <PrevReclick rights="p_t-spread-sys-dept_update">
                <el-button type="text" @click="updateChildMenu(scope.row)">编辑</el-button>
              </PrevReclick>
              <PrevReclick rights="p_t-spread-sys-dept_delete">
                <el-button type="text" @click="delChildMenu(scope.row)">删除</el-button>
              </PrevReclick>
            </template>
        </el-table-column>
    </el-table> 
    <el-pagination class="mt10" background :page-count="totalPage"
                   :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>

    <!-- 新增/编辑一级 -->
    <el-dialog width="30%" center :title="type === 1 ? '新增一级菜单' : '修改一级菜单'" 
    :close-on-click-modal="false" :visible.sync="dialogFormVisible">
        <el-form :model="oneLevelForm">
            <el-form-item label="部门名称" :label-width="formLabelWidth">
                <el-input v-model="oneLevelForm.deptName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="部门地址" :label-width="formLabelWidth">
                <el-input v-model="oneLevelForm.deptAddress" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="排序" :label-width="formLabelWidth">
                <el-input v-model="oneLevelForm.deptSort" type="number" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="部门描述" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="oneLevelForm.remark" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="addspreadSysDeptOne">确 定</el-button>
        </div>
    </el-dialog>

    <!-- 新增/编辑下级 -->
    <el-dialog width="30%" center :title="type === 1 ? '新增下级菜单' : '编辑下级菜单'" 
    :close-on-click-modal="false" :visible.sync="subordinateVisible">
        <el-form :model="subordinateForm">
            <el-form-item label="上级部门" :label-width="formLabelWidth" v-if="type === 1">
                <el-input disabled v-model="deptName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="部门名称" :label-width="formLabelWidth">
                <el-input v-model="subordinateForm.deptName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="部门地址" :label-width="formLabelWidth">
                <el-input v-model="subordinateForm.deptAddress" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="排序" :label-width="formLabelWidth">
                <el-input v-model="subordinateForm.deptSort" type="number" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="部门描述" :label-width="formLabelWidth">
                <el-input type="textarea" v-model="subordinateForm.remark" autocomplete="off"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button @click="subordinateVisible = false">取 消</el-button>
            <el-button type="primary" @click="addspreadSysDeptSubordinate">确 定</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  $sg_spreadSysDeptPage, $sg_spreadSysDeptTree, $sg_spreadSysDeptGet, $sp_spreadSysDeptAdd, $sp_spreadSysDeptUpdate, 

  $sp_spreadSysDeptDelete, 
} from '@/api/department';

export default {
  name: 'department',
  components: {
  },
  data() {
    return {
      pageSize: 10,
      currentPage: 1,
      totalPage: 1,
      // 一级
      dialogFormVisible: false,
      formLabelWidth: '80px',
      oneLevelForm: {
        deptName: '',
        deptAddress: '',
        remark: '',
        deptSort: '',
      },
      // 下级
      subordinateVisible: false,
      subordinateForm: {
        deptName: '',
        deptAddress: '',
        remark: '',
        deptSort: '',
      },
      deptName: '',
      parentId: '',
      //   列表
      tableData: [],
      type: '',
      maps: new Map(), // 定义一个map对象存放节点信息
      mapsId: {},
    };
  },
  created() {
    this.spreadSysDeptPage();
  },
  methods: {
    // 查询一级部门
    spreadSysDeptPage() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: this.pageSize,
        parentId: 0,
      };
      $sg_spreadSysDeptPage(params).then((res) => {
        res.records.forEach((item) => {
          if (item.hasChildren === 0) {
            item.hasChildren = false;
          } else {
            item.hasChildren = true;
          }
        });
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 查询下级
    load(tree, treeNode, resolve) {
      $sg_spreadSysDeptTree(tree.deptId).then((res) => {
        res.forEach((item) => {
          if (item.hasChildren === 0) {
            item.hasChildren = false;
          } else {
            item.hasChildren = true;
          }
        });
        resolve(res);
        this.mapsId = tree.deptId;
        this.maps.set(tree.deptId, { tree, treeNode, resolve });
      });
    },
    // 打开新增/编辑一级部门弹窗
    addOneLevelMenu() {
      // type:1-新增，2-编辑
      this.type = 1;
      this.oneLevelForm = {
        deptName: '',
        deptAddress: '',
        remark: '',
        deptSort: '',
      };
      this.dialogFormVisible = true;
    },
    // 新增/编辑一级部门
    addspreadSysDeptOne() {
      // 新增部门
      if (this.type === 1) {
        const params = {
          parentId: 0,
          ...this.oneLevelForm,
        };
        $sp_spreadSysDeptAdd(params).then(() => {
          this.dialogFormVisible = false;
          this.currentPage = 1;
          this.spreadSysDeptPage();
        });
      } else {
        // 修改部门
        const params = {
          ...this.oneLevelForm,
        };
        $sp_spreadSysDeptUpdate(params).then(() => {   
          this.dialogFormVisible = false;   
          this.spreadSysDeptPage();
        });
      }
    },
    // 打开新增下级部门弹窗
    addChildMenu(row) {
      // type:1-新增，2-编辑
      this.type = 1;
      this.deptName = row.deptName;
      this.subordinateForm = {
        deptName: '',
        deptAddress: '',
        remark: '',
        deptSort: '',
      };
      this.parentId = row.deptId;
      this.mapsId = row.hasChildren ? row.deptId : row.parentId;
      this.subordinateVisible = true;
    },
    // 打开编辑弹窗
    updateChildMenu(row) {
      this.type = 2;
      this.mapsId = row.parentId;
      $sg_spreadSysDeptGet(row.deptId).then((res) => {
        if (row.parentId === 0) {
          this.oneLevelForm = res;
          this.dialogFormVisible = true;
        } else {
          this.deptName = row.deptName;
          this.subordinateForm = res;
          this.subordinateVisible = true;
        }
      });
    },
    // 新增/编辑下级部门
    addspreadSysDeptSubordinate() {
      if (this.type === 1) {
        // 新增下级部门
        const params = {
          parentId: this.parentId,
          ...this.subordinateForm,
        };
        $sp_spreadSysDeptAdd(params).then(() => {
          this.subordinateVisible = false;
          this.spreadSysDeptPage();
          this.refreshNode();
        });
      } else {
        // 编辑下级部门
        const params = {
          ...this.subordinateForm,
        };
        $sp_spreadSysDeptUpdate(params).then(() => {   
          this.subordinateVisible = false;
          this.spreadSysDeptPage();
          this.refreshNode();
        });
      }
    },
    // 删除
    delChildMenu(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        $sp_spreadSysDeptDelete(row.deptId).then(() => {
          this.spreadSysDeptPage();
          this.mapsId = row.parentId;
          this.refreshNode();
        });
      }).catch(() => {});
    },
    // 刷新节点
    refreshNode() {
      if (!this.maps || this.maps.size === 0) {
        return;
      }
      const maps = this.maps.get(this.mapsId);
      if (!maps) {
        return;
      }
      const { tree, treeNode, resolve } = maps;
      this.$set(this.$refs.table.store.states.lazyTreeNodeMap, this.mapsId, []);
      this.load(tree, treeNode, resolve);
    },
    // 翻页
    pageChange() {
      this.spreadSysDeptPage();
    },
  },
};
</script>

<style lang='scss' scoped>
    .departmentManager{
        height: 100%;
        width: 100%;
        background: #fff;
        border-radius: 6px;
        padding: 30px 20px;
        box-sizing: border-box;
    }
    .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
  .departmentBtnGroup{
      margin-bottom: 40px;
  }
</style>
