<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'SystemManager',
  components: {
  },
  data() {
    return {};
  },
};
</script>

<style scoped>

</style>
